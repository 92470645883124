import rapidDB from ".";

export const getFileStreamFromIndexDBAPI = async (id) => {
  try {
    const fileStream = await rapidDB.files.get(id);
    return fileStream
  } catch (error) {
    console.log(error);
  }
};
