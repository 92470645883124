import { io } from "socket.io-client";
import { getNgrokBaseUrl } from "../api/api";

const getBackendBaseUrl = async () => {
  try {
    let retrying = 0;
    let baseUrl = "";
    const innerFunction = async () => {
      try {
        const res = await getNgrokBaseUrl();
        baseUrl = res?.data?.result?.apiInfo?.baseUrl || "";
        if (!baseUrl && retrying < 3) {
          retrying++;
          await innerFunction();
        }
      } catch (error) {
        if (!baseUrl && retrying < 3) {
          retrying++;
          await innerFunction();
        }
        console.log(error);
      }
    };
    await innerFunction();
    return baseUrl || process.env.REACT_APP_MAIN_BACKEND_BASEURL;
  } catch (error) {
    console.log(error);
  }
};

const baseUrl = await getBackendBaseUrl();

export const socket = io(baseUrl);
