import React, { useState } from "react";
import Step1 from "./step1/Step1";
import "./index.scss";
import Step2 from "./step2/Step2";
import Step3 from "./step3/Step3";
import Step4 from "./step4/Step4";
import { Container } from "react-bootstrap";

const Tutorial = () => {
  const [stepNo, setStepNo] = useState(1);

  const handleStepNo = (step) => {
    setStepNo(Number(step));
  };
  return (
    <Container className="tutorial-steps-wrapper position-relative">
      {stepNo === 1 && (
        <Step1 handleStepNo={handleStepNo} selectedStep={stepNo} />
      )}
      {stepNo === 2 && (
        <Step2 handleStepNo={handleStepNo} selectedStep={stepNo} />
      )}
      {stepNo === 3 && (
        <Step3 handleStepNo={handleStepNo} selectedStep={stepNo} />
      )}
      {stepNo === 4 && (
        <Step4 handleStepNo={handleStepNo} selectedStep={stepNo} />
      )}
    </Container>
  );
};

export default Tutorial;
