import React from "react";
import "./step3.scss";
import invitation_1 from "../../../assets/images/sample-1.png";
import invitation_2 from "../../../assets/images/sample-2.png";
import invitation_3 from "../../../assets/images/sample-3.png";

const Step3 = (props) => {
  const { handleStepNo, selectedStep } = props;
  const stepNumbers = [1, 2, 3, 4];

  const variables = ["Full Name", "First Name", "Last Name", "Number"];
  const invitations = [invitation_1, invitation_2, invitation_3];

  return (
    <div className="step3-wrapper d-flex">
      <div className="left-section d-flex">
        <div className="page-no-section">
          {stepNumbers.map((step, i) => {
            return (
              <div
                key={`step-${i + 1}`}
                className={`round-with-number d-flex align-items-center justify-content-center ${
                  i !== 0 ? "mt-30" : ""
                }  fs-35 ${selectedStep === step ? "selected" : ""}`}
                onClick={() => {
                  handleStepNo(step);
                }}
              >
                {step}
              </div>
            );
          })}
        </div>
        <div>
          {variables.map((variable, i) => (
            <div
              className={`variable-box ${
                i !== 0 ? "mt-10" : ""
              } d-flex align-items-center`}
            >
              <div className="dots-wrapper d-flex">
                <div className="left-dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
                <div className="right-dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
              <p className="mb-0  fs-16"></p>
              {variable}
            </div>
          ))}
          <div className="fs-38 fw-800 secondary-title">
            <p className="text-primary mb-0">Drag your variables</p>to the
            <br /> correct location
          </div>
        </div>
      </div>
      <div className="right-section">
        <div className="invitations-wrapper">
          {invitations.map((invitation, i) => (
            <img src={invitation} key={`invitation-${i + 1}`} alt="" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Step3;
