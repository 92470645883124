import React, { useEffect, useState } from "react";
import { Button, Card, ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";
export const WaitingMessage = () => {
  const { excelData } = useSelector((state) => state.files);

  const [guests, setGuests] = useState(0);

  const handleGuests = () => {
    const guests = excelData?.guestData?.length;
    setGuests(guests);
  };

  useEffect(() => {
    excelData?.guestData?.length > 0 && handleGuests();
  }, [excelData]);
  return (
    <>
      <Card style={{ width: "30rem" }}>
        <Card.Body>
          {/* <Card.Title>Invitation status</Card.Title> */}
          <Card.Text>
            <ul>
              <li>We got your invitation request.</li>
              <li>
                You have to pay ₹1 per invitation. <br /> (i.e. :- ₹1 x {guests}
                {` Guest`} = ₹ {guests})
              </li>
              <li>We will contact for next move.</li>
              <li>
                If you have any concern then you are free to contact help-line.
              </li>
              <li>
                <div>
                  <b>Help-Line-no</b> {" : " + "+919712603487."}
                </div>
              </li>
              <li>
                <div>
                  <b>Amount</b> : {`₹ ${guests * 1}`}
                </div>
              </li>
            </ul>
          </Card.Text>
          <ProgressBar animated now={100} />
        </Card.Body>
      </Card>
    </>
  );
};
