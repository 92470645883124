const s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};

const shortUuid = () => {
  return s4() + s4();
};

const midUuid = () => {
  return s4() + s4() + s4();
};

const longUuid = () => {
  return s4() + "-" + s4() + s4() + "-" + s4();
};

export { shortUuid, midUuid, longUuid };
