import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fileStream: null,
  excelData: {
    excelHeaders: ["name", "mobile"],
    guestData: [{ name: "", mobile: "" }],
  },
  pdfData: null,
  isBackFromVariable: false,
  fileType: "",
  mimeType: "",
  isPreviewInvitation: false,
  isAddingManually: true,
};

export const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    setFileStream: (state, action) => {
      state.fileStream = action.payload;
    },
    setExcelData: (state, action) => {
      state.excelData = action.payload;
    },
    setPdfData: (state, action) => {
      state.pdfData = action.payload;
    },
    setFileType: (state, action) => {
      state.fileType = action.payload;
    },
    onBackFromVariable: (state, action) => {
      state.isBackFromVariable = action.payload;
    },
    setMimeType: (state, action) => {
      state.mimeType = action.payload;
    },
    setIsPreviewInvitation: (state, action) => {
      state.isPreviewInvitation = action.payload;
    },
    setIsAddingManually: (state, action) => {
      state.isAddingManually = action.payload;
    },
  },
});

export const {
  setFileStream,
  setExcelData,
  setPdfData,
  onBackFromVariable,
  setFileType,
  setMimeType,
  setIsPreviewInvitation,
  setIsAddingManually,
} = filesSlice.actions;

export default filesSlice.reducer;
