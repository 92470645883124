import React from "react";
import NavbarComponent from "../components/navbar";
import HeroSection from "../components/heroSection";
import Tutorial from "../components/tutorialSection";
import Testimonial from "../components/testimonialSection";
import CallToAction from "../components/callToAction";
import Footer from "../components/footer";

const HomePage = () => {
  return (
    <div className="bg-yellow">
      <NavbarComponent />
      <HeroSection />
      <Tutorial />
      {/* <Testimonial /> */}
      {/* <CallToAction /> */}
      {/* <Footer /> */}
    </div>
  );
};

export default HomePage;
