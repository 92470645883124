import React, { useEffect, useRef, useState } from "react";
import { getInvitationWithStatusById } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import {
  setInvitationWithUsers,
  setIsShowImportExportButtons,
  setIsShowSuccessMessage,
  setIsShowWaitingMessage,
} from "../../redux-slice/statusSlice";
import Status from "./status";
import { useParams } from "react-router-dom";
import { socket } from "../../config/socket.config";
import { toast } from "react-hot-toast";
import { setExcelData } from "../../redux-slice/filesSlice";

const Invitation = () => {
  const [user, setUser] = useState({
    loggedIn: false,
    loggedOut: false,
  });
  const [invitationWithUsers_, setInvitationWithUsers_] = useState();
  const [qrCode, setQrCode] = useState({
    string: "",
    isLoading: false,
  });
  const [currentRow, setCurrentRow] = useState(-1);
  const [newExcelData, setNewExcelData] = useState({
    headers: [],
    values: [],
  });

  const dispatch = useDispatch();
  const params = useParams();
  const messageToast = {
    toast,
  };
  const invitationId = params?.invitationId;

  const { invitationWithUsers } = useSelector((state) => state.status);
  const { excelData } = useSelector((state) => state.files);
  const [screenshot, setScreenshot] = useState("");

  const invitationWithUsersRef = useRef(null);

  useEffect(() => {
    if (invitationWithUsers) {
      invitationWithUsersRef.current = invitationWithUsers;
    }
  }, [invitationWithUsers]);

  useEffect(() => {
    invitationId && handleGetInvitationStatus(invitationId);
    invitationId && initializeSocket(socket);
  }, []);

  const initializeSocket = (socket) => {
    socket.on("invitationStatus", function (data) {
      updateStatus(data);
    });
    socket.on("qrCode", function (data) {
      setQrCode({
        string: data?.qrCode,
        isLoading: false,
      });
    });
    socket.on("logout", function (data) {
      dispatch(setIsShowImportExportButtons(true));
      dispatch(setIsShowSuccessMessage(true));
      setUser({
        loggedIn: false,
        loggedOut: true,
      });
    });

    socket.on("isQrLoading", function (value) {
      setQrCode({
        isLoading: value,
      });
    });
    socket.on("currentUser", (currentUser) => {
      updateCurrentUserRow(currentUser);
    });
    socket.on("message", ({ type, message }) => {
      messageToast.toast[type](message, {
        duration: 3000,
      });
    });
    socket.on("qrScanned", function (value) {
      if (value) {
        setUser({
          loggedIn: true,
        });
        setQrCode({
          string: "",
          isLoading: false,
        });
      }
    });
    socket.on("screenshot", (screenshot) => {
      setScreenshot(screenshot);
    });

    socket.on("isPaymentDone", (isPayment) => {
      isPayment && dispatch(setIsShowWaitingMessage(false));
    });
  };

  const updateCurrentUserRow = (currentUser) => {
    const clonedInvitationWithUsersRef = {
      ...invitationWithUsersRef.current,
      users: [...invitationWithUsersRef.current.users],
    };

    const currentUserIndex = clonedInvitationWithUsersRef.users.findIndex(
      (user) => user._id === currentUser.userId
    );
    setCurrentRow(currentUserIndex);
  };

  const updateStatus = (data) => {
    const clonedInvitationWithUsersRef = {
      ...invitationWithUsersRef.current,
      users: [...invitationWithUsersRef.current.users],
    };
    const currentUserIndex = clonedInvitationWithUsersRef.users.findIndex(
      (user) => user._id === data.user
    );
    clonedInvitationWithUsersRef.users[currentUserIndex] = {
      ...clonedInvitationWithUsersRef.users[currentUserIndex],
      status: data.status,
    };
    invitationWithUsersRef.current = clonedInvitationWithUsersRef;
    setInvitationWithUsers_(clonedInvitationWithUsersRef);

    // Update ExcelData
    // const clonedExcelData = JSON.parse(JSON.stringify(excelData));
    // const index = clonedExcelData.guestData.findIndex(
    //   (guest) => guest._id === data.user
    // );
    // clonedExcelData.guestData[index] = {
    //   ...clonedExcelData.guestData[index],
    //   status: data.status,
    // };
    // dispatch(setExcelData(clonedExcelData));
  };

  const handleGetInvitationStatus = async (id) => {
    try {
      const res = await getInvitationWithStatusById(id);
      const qrCode = res?.data?.result?.qrCode || "";
      if (!!qrCode) {
        setQrCode({ string: qrCode });
      }
      if (!res) {
        toast.error(`Invitation not found`);
      }
      dispatch(setInvitationWithUsers(res.data.result));
      setInvitationWithUsers_(res.data.result);
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  const filterUsersByStatus = (status) => {
    const users = invitationWithUsersRef?.current?.users;
    const filteredUsers =
      users?.length > 0 ? users.filter((user) => user.status === status) : [];
    return filteredUsers;
  };

  const sortArray = (objectsArray, keysArray) => {
    const sortedObjectsArray = objectsArray.map((obj) => {
      const sortedObj = {};
      keysArray.forEach((key) => {
        sortedObj[key] = obj[key];
      });
      return sortedObj;
    });
    return sortedObjectsArray;
  };

  const handleNewExcelData = () => {
    let headers = excelData?.excelHeaders;
    if (headers?.length > 0) {
      headers = [...headers];
      let values = [];
      const usersWithFailedStatus = filterUsersByStatus("failed");
      usersWithFailedStatus.length > 0 &&
        usersWithFailedStatus.map((user) => {
          const modifiedUser = {
            _id: user._id,
            ...user.details,
            status: user.status,
          };
          values.push(modifiedUser);
        });
      values = sortArray(values, headers);
      setNewExcelData({ ...newExcelData, headers, values });
    }
  };

  useEffect(() => {
    invitationWithUsers?.payment && dispatch(setIsShowWaitingMessage(false));
  }, [invitationWithUsers]);

  useEffect(() => {
    if (excelData && invitationWithUsersRef?.current) {
      handleNewExcelData();
    }
  }, [excelData, invitationWithUsersRef?.current]);

  return (
    <div className="h-100 ">
      {invitationId && invitationWithUsersRef?.current && (
        <Status
          invitationWithUsers={invitationWithUsersRef.current}
          qrCode={qrCode}
          user={user}
          currentRow={currentRow}
          newExcelData={newExcelData}
          screenshot={screenshot}
        />
      )}
    </div>
  );
};

export default Invitation;
