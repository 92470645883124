export const debounce = (func, delay) => {
  let timer;
  return function () {
    let self = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(self, args);
    }, delay);
  };
};

export const deepClone = (obj) => {
  try {
    if (obj === null || typeof obj !== "object") {
      return obj;
    }

    if (Array.isArray(obj)) {
      const cloneArr = [];
      for (let i = 0; i < obj.length; i++) {
        cloneArr[i] = deepClone(obj[i]);
      }
      return cloneArr;
    }

    const cloneObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        cloneObj[key] = deepClone(obj[key]);
      }
    }
    return cloneObj;
  } catch (error) {
    console.log(error);
  }
};
