import React from "react";
import "./heroSection.scss";
import UploadFile from "../uploadFile";
import { Button, Container } from "react-bootstrap";
import { curved_design, right_arrow } from "../../assets/icons";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <div className="position-relative">
      <div className="curved_design_wrapper position-absolute">
        {curved_design()}
      </div>
      <Container className="hero-section ">
        <div className="d-flex flex-wrap position-relative">
          <div className=" custom-input-wrapper-parent mx-auto">
            <UploadFile />
          </div>
          <div className=" d-flex align-items-center primary-heading-wrapper px-0">
            <div className="">
              <p className="fs-50 primary-heading fw-800 mb-0">
                Fastest way <br /> for sending dynamic
                <span className="text-primary"> messages</span> to whatsapp
              </p>
              <div>
                <Button
                  variant="primary"
                  className="d-flex align-items-center justify-content-center free-trial-button p-0"
                  onClick={() => {
                    navigate("/send-invitation");
                  }}
                >
                  <span className="pe-2 fs-25">Free Trial</span>{" "}
                  {right_arrow("36px", "36px")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeroSection;
