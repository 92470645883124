export const getColorInMultipleFormate = (hexValue) => {
  const red = parseInt(hexValue.slice(1, 3), 16); // parse the hex value for red component
  const green = parseInt(hexValue.slice(3, 5), 16); // parse the hex value for green component
  const blue = parseInt(hexValue.slice(5, 7), 16); // parse the hex value for blue component
  const rgbValue = `rgb(${red}, ${green}, ${blue})`; // construct the RGB value string
  return {
    rgb: {
      r: red,
      g: green,
      b: blue,
      string: rgbValue,
    },
    hex: hexValue,
  };
};
