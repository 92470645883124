import React from "react";
import "./step1.scss";
import { Button } from "react-bootstrap";
import { file_icon } from "../../../assets/icons";
import img_logo from "../../../assets/logo/image-logo.png";
import pdf_logo from "../../../assets/logo/pdf-logo.png";
import invitation_1 from "../../../assets/images/invitation-1.png";
import invitation_2 from "../../../assets/images/invitation-2.png";
import invitation_3 from "../../../assets/images/invitation-3.png";

const Step1 = (props) => {
  const { handleStepNo, selectedStep } = props;

  const stepNumbers = [1, 2, 3, 4];
  const invitations = [invitation_1, invitation_2, invitation_3];
  return (
    <div className="step1_wrapper d-flex">
      <div className="left-section">
        <p className="fs-48 fw-800 mb-0">How it works ?</p>
        <div className=" d-flex">
          <div className="mt-69 step-no-wrapper">
            {stepNumbers.map((step, i) => {
              return (
                <div
                  key={`step-${i + 1}`}
                  className={`round-with-number d-flex align-items-center justify-content-center ${
                    i !== 0 ? "mt-30" : ""
                  }  fs-35 ${selectedStep === step ? "selected" : ""}`}
                  onClick={() => {
                    handleStepNo(step);
                  }}
                >
                  {step}
                </div>
              );
            })}
          </div>
          <div className="mt-83 px-0">
            <Button
              variant="light"
              className="d-flex align-items-center justify-content-center select-file-button p-0"
            >
              {file_icon()}
              <span className="ps-4 fs-22 text-primary">Select File</span>
            </Button>
            <div className="d-flex mt-52">
              <div className="mr-41">
                <img src={img_logo} alt="img_logo" />
                <p className="fs-22 text-center mt-22 mb-0">IMAGE</p>
              </div>
              <div>
                <img src={pdf_logo} alt="img_logo" />
                <p className="fs-22 text-center mt-22 mb-0">PDF</p>
              </div>
            </div>
            <p className="fs-38 fw-bold mt-56 secondary-title fw-800 mb-0">
              <span className="text-primary">
                Upload
                <br /> your
                <br />
                graphics
              </span>
              ,
              <br />
              or invitations
            </p>
          </div>
        </div>
      </div>
      <div className="right-section">
        <div className="invitations-wrapper">
          {invitations.map((invitation, i) => (
            <img src={invitation} key={`invitation-${i + 1}`} alt="" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Step1;
