export const capitalizeFirstLetter = (string = "") => {
  return string
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const validateString = (value) => {
  return !!String(value).trim();
};

export const getOnlyUrl = (url = "") => {
  if (url.includes("?")) {
    return url?.split("?")[0];
  }
  return url;
};