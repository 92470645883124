import React, { useEffect, useMemo, useRef } from "react";
import "./index.scss";
import { delete_icon } from "../../../assets/icons";

const VariableBox = (props) => {
  const {
    isDraggable,
    dragStart,
    header,
    variableData = {},
    index,
    removeDraggedVariable,
    isDeletable,
    handleVariableTextRef = undefined,
    type = "",
    innerIndex,
    bgColor = "",
    onMouseUpDraggedVariable,
    onMouseDownDraggedVariable,
    addMultipleVariableRefs,
    elementForGhost,
  } = props;

  const { fontSize } = variableData;

  const variableTextRef = useRef();

  useEffect(() => {
    if (variableTextRef.current && handleVariableTextRef && type === "image") {
      // this method called only once when user drag & drop variable
      handleVariableTextRef(variableTextRef.current, innerIndex);
    }
    if (variableTextRef.current && handleVariableTextRef && type === "pdf") {
      // this method called only once when user drag & drop variable
      handleVariableTextRef(variableTextRef.current, innerIndex, index, variableData);
    }
    // eslint-disable-next-line
  }, []);

  const renderVariable = useMemo(() => (
    <div
      className={`variable-box flex align-items-center`}
      style={{ background: bgColor ? bgColor : "#fff" }}
      draggable={isDraggable}
      onDragStart={(e) => {
        e.stopPropagation();
        dragStart(e, header, variableData, index, type);
      }}
      id={`dropped-variable.${index || 0}.${innerIndex}`}
      // {...(addMultipleVariableRefs && { ref: addMultipleVariableRefs })}
      ref={(el) => {
        addMultipleVariableRefs && addMultipleVariableRefs(el);
      }}
      onMouseUp={onMouseUpDraggedVariable}
      onMouseDown={onMouseDownDraggedVariable}
      onDragEnd={() => {
        if (
          variableTextRef.current &&
          handleVariableTextRef &&
          type === "image"
        ) {
          handleVariableTextRef(variableTextRef.current, innerIndex);
        }
        if (
          variableTextRef.current &&
          handleVariableTextRef &&
          type === "pdf"
        ) {
          handleVariableTextRef(variableTextRef.current, innerIndex, index);
        }
        // this method called when user re-arrange the variable
      }}
    >
      <div className="dots-wrapper d-flex">
        <div className="left-dots">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
        <div className="right-dots">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>
      {isDeletable ? (
        <div
          className="d-flex justify-content-between flex-grow-1 align-items-center"
          ref={variableTextRef}
        >
          <p className="mb-0" style={{ fontSize: `${fontSize}px` }}>
            {header}
          </p>
          <div
            className="me-3"
            onClick={() => {
              removeDraggedVariable(innerIndex, index);
            }}
          >
            {delete_icon(22)}
          </div>
        </div>
      ) : (
        <p className="mb-0 fs-22">{header}</p>
      )}
    </div>
  ));
  // ), [isDeletable, header, fontSize, variableData, isDraggable]);

  return (
    <div className="variable-box-wrapper custom-scrollbar">
      {renderVariable}
    </div>
  );
};

export default VariableBox;
