import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import { Badge, Table } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  addFieldToArrayObject,
  checkFieldAvailability,
} from "../../utils/array";
import { setExcelData } from "../../redux-slice/filesSlice";
import { updateUser } from "../../api/api";
import { save_icon } from "../../assets/icons";
import { setInvitationWithUsers } from "../../redux-slice/statusSlice";

const GuestData = ({ currentRow, invitationWithUsers }) => {
  const { excelData } = useSelector((state) => state.files);
  // const { invitationWithUsers } = useSelector((state) => state.status);
  const [current, setCurrent] = useState(0);
  const location = useLocation();
  const dispatch = useDispatch();
  const currentLocation = location.pathname;
  const params = useParams();
  const invitationId = params?.invitationId;
  const [isSortFailed, setIsSortFailed] = useState(true);
  const [guestData, setGuestData] = useState([]);
  const [excelHeaders, setExcelHeaders] = useState([]);

  const updateGuestData = () => {
    let clonedExcelData = JSON.parse(JSON.stringify(excelData));
    clonedExcelData.guestData = addFieldToArrayObject(
      clonedExcelData.guestData,
      "isUpdating",
      "false"
    );
    dispatch(setExcelData(clonedExcelData));
    setGuestData(clonedExcelData.guestData);
  };

  const sortGuestData = (type) => {
    if (type === "status") {
      let clonedGuestData = JSON.parse(JSON.stringify(guestData));
      // Toggle the sorting order
      setIsSortFailed(!isSortFailed);
      const sortedByStatus = clonedGuestData?.sort((a, b) => {
        if (a.status === "failed" && b.status === "success") {
          return isSortFailed ? -1 : 1; // Toggle the sorting order
        } else if (a.status === "success" && b.status === "failed") {
          return isSortFailed ? 1 : -1; // Toggle the sorting order
        } else {
          return 0; // leave the order unchanged
        }
      });
      clonedGuestData = [...sortedByStatus];
      setGuestData(clonedGuestData);
    }
  };

  const handleUpdateUser = async (user) => {
    const _id = user._id;
    const details = {};
    Object.entries(user).map((entry) => {
      if (
        !(
          entry[0] === "isUpdating" ||
          entry[0] === "_id" ||
          entry[0] === "status"
        )
      ) {
        Object.assign(details, { [entry[0]]: entry[1] });
      }
    });
    const body = { details, _id, status: "pending" };
    const isUserUpdated = await updateUser(body);
    return isUserUpdated;
  };

  const handleEdit = async (i) => {
    let findedObject = { ...guestData[i] };
    if (findedObject.isUpdating === "true") {
      const isUserUpdated = await handleUpdateUser(findedObject);
      if (isUserUpdated) {
        findedObject.status = "pending";
        let clonedExcelData = JSON.parse(JSON.stringify(excelData));
        clonedExcelData.guestData[i] = findedObject;
        dispatch(setExcelData(clonedExcelData));
      }
    }
    findedObject = {
      ...findedObject,
      isUpdating: findedObject.isUpdating === "true" ? "false" : "true",
    };
    updateInvitationWithUsers(findedObject);
    setGuestData([
      ...guestData.slice(0, i), // Keep the elements before the index 'i'
      findedObject, // Insert the updated 'findedObject' at index 'i'
      ...guestData.slice(i + 1), // Keep the elements after the index 'i'
    ]);
  };

  const handleUpdateGuestDetails = (key, value, index) => {
    let clonedGuestData = JSON.parse(JSON.stringify(guestData));
    clonedGuestData[index][key] = value;
    // dispatch(setExcelData(clonedExcelData));
    setGuestData(clonedGuestData);
  };

  const handleGuestData = () => {
    const clonedGuestData = JSON.parse(JSON.stringify(excelData?.guestData));
    const filteredGuestData = clonedGuestData?.map((guest, i) => {
      delete guest?._id;
      delete guest?.status;
      delete guest?.isUpdating;
      return guest;
    });
    filteredGuestData?.length > 0 && setGuestData(filteredGuestData);
  };

  const handleExcelHeaders = () => {
    const clonedExcelHeaders = JSON.parse(
      JSON.stringify(excelData?.excelHeaders)
    );
    if (currentLocation === `/invitation-status/${invitationId}`) {
      setExcelHeaders(clonedExcelHeaders);
    } else {
      const filterExcelHeaders = clonedExcelHeaders?.filter((header, i) => {
        if (header !== "_id" && header !== "status") {
          return true;
        }
      });
      setExcelHeaders(filterExcelHeaders);
    }
  };

  const updateInvitationWithUsers = (guest) => {
    const clonedInvitationWithUsers = JSON.parse(
      JSON.stringify(invitationWithUsers)
    );
    console.log("clonedInvitationWithUsers", clonedInvitationWithUsers);
    const clonedGuest = JSON.parse(JSON.stringify(guest));
    const status = clonedGuest?.status || "pending";
    const index = clonedInvitationWithUsers?.users.findIndex(
      (user) => user?._id === guest?._id
    );
    delete clonedGuest?._id;
    delete clonedGuest?.isUpdating;
    delete clonedGuest?.status;
    clonedInvitationWithUsers.users[index].details = clonedGuest;
    clonedInvitationWithUsers.users[index].status = status;
    dispatch(setInvitationWithUsers(clonedInvitationWithUsers));
  };

  useEffect(() => {
    setInterval(() => {
      setCurrent(current + 2);
    }, 1000);
  }, []);

  useEffect(() => {
    if (
      excelData?.guestData &&
      currentLocation === `/invitation-status/${invitationId}`
    ) {
      const clonedGuestData = [...excelData?.guestData];
      !checkFieldAvailability(clonedGuestData, "isUpdating")
        ? updateGuestData()
        : setGuestData(clonedGuestData);
    } else if (excelData?.guestData?.length > 0) {
      handleGuestData();
    }
  }, [excelData]);

  useEffect(() => {
    excelData?.guestData && handleExcelHeaders();
  }, [excelData]);

  return (
    <table className="custom-table" bordered hover>
      <thead>
        <tr>
          {excelHeaders?.length > 0 &&
            excelHeaders?.map((header, index) => {
              return (
                <React.Fragment key={`header.${index}`}>
                  {index === 0 && <th>#</th>}
                  {header !== "_id" &&
                    header !== "isUpdating" &&
                    (header !== "status" ? (
                      <th>
                        <div className="d-flex align-items-center justify-content-center ">
                          <span className="">{header}</span>
                        </div>
                      </th>
                    ) : (
                      <th>
                        <div className="d-flex align-items-center justify-content-center ">
                          <span className="me-1">{header}</span>
                          <span
                            className="cursor-pointer"
                            onClick={() => {
                              sortGuestData("status");
                            }}
                          >
                            &#8645;
                          </span>
                        </div>
                      </th>
                    ))}
                </React.Fragment>
              );
            })}
          {currentLocation === `/invitation-status/${invitationId}` && (
            <th>
              <div className="d-flex align-items-center justify-content-center ">
                <span className="me-1">Edit</span>
              </div>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {guestData?.length > 0 &&
          guestData?.map((guestDetails, index) => {
            return (
              <tr
                key={`invitation-row-${index}`}
                className={index === currentRow ? "active-row" : ""}
              >
                <td>{index + 1}</td>
                {Object.values(guestDetails).length > 0 &&
                  Object.values(guestDetails).map(
                    (invitation, i) =>
                      Object.keys(guestDetails)[i] !== "_id" &&
                      (currentLocation ===
                      `/invitation-status/${invitationId}` ? (
                        Object.keys(guestDetails)[i] !== "status" &&
                        i !== Object.values(guestDetails).length - 1 ? (
                          <td
                            key={`invitation-data-${index}.${i}`}
                            className="row-data"
                          >
                            <div className="d-flex align-items-center justify-content-center ">
                              {guestData[index].isUpdating === "true" ? (
                                <div className="d-inline-block">
                                  <input
                                    type="text"
                                    className="w-100"
                                    value={invitation}
                                    onChange={(e) => {
                                      handleUpdateGuestDetails(
                                        Object.keys(guestDetails)[i],
                                        e.target.value,
                                        index
                                      );
                                    }}
                                  />
                                </div>
                              ) : (
                                <span className="ms-1">{invitation}</span>
                              )}
                            </div>
                          </td>
                        ) : Object.keys(guestDetails)[i] !== "isUpdating" ? (
                          <td
                            key={`invitation-data-${index}.${i}`}
                            className="row-data"
                          >
                            <Badge
                              pill
                              className={`py-2 px-3 d-block text-capitalize fw-500 bg-${
                                invitation.toLowerCase() === "success"
                                  ? "success  text-white"
                                  : invitation.toLowerCase() === "failed"
                                  ? "danger  text-white"
                                  : "warning text-dark"
                              }                          
                          `}
                            >
                              <div className="process">{invitation}</div>
                            </Badge>
                          </td>
                        ) : (
                          <td>
                            <div className="d-flex align-items-center justify-content-center ">
                              {currentLocation ===
                                `/invitation-status/${invitationId}` &&
                                (guestData[index]?.status !== "success" ? (
                                  invitation === "true" ? (
                                    <span
                                      aria-disabled
                                      className="cursor-pointer prevent-select"
                                      onClick={() => {
                                        handleEdit(index);
                                      }}
                                    >
                                      {save_icon()}
                                    </span>
                                  ) : (
                                    <span
                                      className="cursor-pointer prevent-select"
                                      onClick={() => {
                                        handleEdit(index);
                                      }}
                                    >
                                      &#9998;
                                    </span>
                                  )
                                ) : (
                                  <span
                                    className="prevent-select"
                                    style={{ color: "green" }}
                                  >
                                    &#10003;
                                  </span>
                                ))}
                            </div>
                          </td>
                        )
                      ) : (
                        <td
                          key={`invitation-data-${index}.${i}`}
                          className="row-data"
                        >
                          <div className="d-flex align-items-center justify-content-center ">
                            <span className="ms-1">{invitation}</span>
                          </div>
                        </td>
                      ))
                  )}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default GuestData;
