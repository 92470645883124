import React from "react";
import NavbarComponent from "../components/navbar";
import CreateInvitation from "../components/createInvitation";
// import { curved_design } from "../assets/icons";

const SendInvitation = () => {
  return (
    <div className="bg-yellow h-100vh d-flex flex-column position-relative">
      {/* <div className="bg-design-wrapper position-absolute">{curved_design()}</div> */}
      <NavbarComponent />
      <CreateInvitation />
    </div>
  );
};

export default SendInvitation;
