import axios from "axios";

import { getNgrokUrlFromLocalStorage } from "../utils/url";

export const uploadFileApi = async (data) => {
  try {
    const baseUrl = getNgrokUrlFromLocalStorage();
    const response = await axios.post(`${baseUrl}/api/excel/upload`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const generateZip = async (data, fileType) => {
  try {
    const endpoint = fileType === "image" ? "generate" : "generate-pdf-zip";
    const apiPromise = new Promise((res, rej) => {
      // const baseUrl = "https://97g97nkhl7.execute-api.us-east-1.amazonaws.com";
      // const baseUrl = "http://ec2-54-209-240-173.compute-1.amazonaws.com:9000";
      const baseUrl = getNgrokUrlFromLocalStorage();
      fetch(`${baseUrl}/api/zip/${endpoint}`, {
        method: "post",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        body: data,
      }).then((response) => {
        if (!response.ok) {
          rej();
          throw new Error("Network response was not ok");
        }
        res(response.blob());
      });
    });
    const res = await apiPromise;
    return res;
  } catch (error) {
    console.log("error", error);
  }
};

export const uploadPdf = async (data) => {
  try {
    const baseUrl = getNgrokUrlFromLocalStorage();
    const response = await axios.post(`${baseUrl}/api/pdf/upload`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const createOrder = async (data) => {
  try {
    const baseUrl = getNgrokUrlFromLocalStorage();
    const response = await axios.post(
      `${baseUrl}/api/payment/create-order`,
      data
    );
    return response;
  } catch (error) {
    console.error(error.message);
  }
};

export const uploadAllDataToDb = async (data) => {
  try {
    const baseUrl = getNgrokUrlFromLocalStorage();
    const response = await axios.post(`${baseUrl}/api/invitation`, data);
    return response;
  } catch (error) {
    console.error(error.message);
  }
};

export const getInvitationWithStatusById = async (invitationId) => {
  try {
    const baseUrl = getNgrokUrlFromLocalStorage();
    const response = await axios.get(
      `${baseUrl}/api/invitation/${invitationId}`
    );
    return response;
  } catch (error) {
    console.error(error.message);
  }
};
export const getAllInvitations = async () => {
  try {
    const baseUrl = getNgrokUrlFromLocalStorage();
    const response = await axios.get(`${baseUrl}/api/invitation`);
    return response;
  } catch (error) {
    console.error(error.message);
  }
};

export const generateImageZip = async (data, type) => {
  try {
    // const baseUrl = "https://97g97nkhl7.execute-api.us-east-1.amazonaws.com";
    // const baseUrl = "http://ec2-54-209-240-173.compute-1.amazonaws.com:9000";
    const baseUrl = getNgrokUrlFromLocalStorage();
    const response = await fetch(`${baseUrl}/api/zip/generate-${type}-zip`, {
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
      },
      body: data,
    });
    // return response;
    return response.blob();
  } catch (error) {
    console.log("error", error);
  }
};

export const updateUser = async (body) => {
  try {
    const baseUrl = getNgrokUrlFromLocalStorage();
    const res = await axios.put(`${baseUrl}/api/user/update`, body);
    const isUserUpdated = res.data.result;
    return isUserUpdated;
  } catch (error) {
    console.log(error.message);
  }
};

export const updateUsers = async (body) => {
  try {
    const baseUrl = getNgrokUrlFromLocalStorage();
    const res = await axios.put(`${baseUrl}/api/user/update-users`, body);
    const isUsersUpdated = res.data.result;
    return isUsersUpdated;
  } catch (error) {
    console.log(error.message);
  }
};

export const getNgrokBaseUrl = async () => {
  try {
    const baseUrl = process.env.REACT_APP_MAIN_BACKEND_BASEURL;

    const res = await axios.get(
      `${baseUrl}/api/api-info?apiName=${process.env.REACT_APP_NGROK_URL_KEY}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
