import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import "./upload.scss";
import { excel_icon, export_icon, file_icon } from "../../assets/icons";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  setExcelData,
  setFileStream,
  setFileType,
  setMimeType,
  setPdfData,
} from "../../redux-slice/filesSlice";
// import excel_icon from "../../assets/logo/excel-76.png";
import { convertExcelToJson } from "../../utils/excel";
import GuestData from "../guestData";
import { dataURLtoBuffer, dataURLtoFile, fileToDataURL } from "../../utils";
import { pdfjs } from "react-pdf";
import {
  setPdfDraggedVariables,
  setStoredDraggedVariables,
} from "../../redux-slice/variablesSlice";
import PreviewPdf from "../variableInsertion/previewPdf";
import { useLocation, useNavigate } from "react-router";
import rapidDB from "../../indexDB";
import { getFileStreamFromIndexDBAPI } from "../../indexDB/getFromIndexDB";
import EditableTable from "../editableTable";

const UploadFile = (props) => {
  const { isUploadExcel } = props;
  const { excelData, fileStream_, pdfData_, fileType,isAddingManually } = useSelector(
    (state) => state.files
  );

  const [fileStream, setFileStream] = useState("");
  const [pdfData, setPdfData] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [numPages, setNumPages] = useState(fileType === "image" ? 1 : 0);
  const [pdfBuffer, setPdfBuffer] = useState();

  useEffect(() => {
    getFileStreamFromIndexDB(fileType);
  }, []);

  const getFileStreamFromIndexDB = async (fileType) => {
    try {
      if (fileType === "image") fileType = "imageFile";
      if (fileType === "pdf") fileType = "pdfFile";
      const fileObject = await getFileStreamFromIndexDBAPI(fileType);
      if (fileObject?.imageStream) {
        setFileStream(fileObject.imageStream);
        const imgUrl =
          fileStream !== null
            ? URL.createObjectURL(dataURLtoFile(fileObject.imageStream))
            : null;
        setImgUrl(imgUrl);
      }
      if (fileObject?.pdfStream) {
        setPdfData(fileObject.pdfStream);
        const imgUrl =
          fileStream !== null
            ? URL.createObjectURL(dataURLtoFile(fileObject.pdfStream))
            : null;
        setImgUrl(imgUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const _inputProps = isUploadExcel
    ? {
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
          [".xltx"],
        "application/vnd.ms-excel-template": [".xlt"],
        "application/vnd.ms-excel.sheet.macroEnabled.12": [".xlsm"],
        "application/vnd.ms-excel.template.macroEnabled.12": [".xltm"],
        "application/vnd.ms-excel.sheet.binary.macroEnabled.12": [".xlsb"],
        "text/csv": [".csv"],
        "text/tab-separated-values": [".tab"],
      }
    : {
        "image/png": [".png"],
        "image/jpeg": [".jpeg"],
        "image/jpg": [".jpg"],
        "application/pdf": [".pdf"],
      };

  useEffect(() => {
    if (pdfData) {
      getPdfBuffer();
    }
  }, [pdfData]);

  const getPdfBuffer = async () => {
    const buffer = await dataURLtoBuffer(pdfData);
    setPdfBuffer(buffer);
    loadPdfFromBuffer(buffer);
  };

  const loadPdfFromBuffer = (pdfBuffer) => {
    const pdf = pdfjs.getDocument({ data: pdfBuffer });
    pdf.promise.then((pdfDocument) => {
      setNumPages(pdfDocument.numPages);
      createPagesVariablesArray(pdfDocument.numPages);
    });
  };

  const createPagesVariablesArray = (pages) => {
    let allPagesVariables = {};
    for (let i = 0; i < pages; i++) {
      allPagesVariables[i] = [];
    }
    dispatch(setPdfDraggedVariables(allPagesVariables));
  };

  const handleUploadingImage = async (imageStream, mimeType) => {
    // localStorage.setItem("imageStream", imageStream);
    await rapidDB.files.put({
      id: "imageFile",
      imageStream,
    });
    // regenerateFileStream(imageStream);
    await getFileStreamFromIndexDB("imageFile");
    dispatch(setMimeType(mimeType));
    // dispatch(setFileStream(imageStream));
    // dispatch(setPdfData(null));
    dispatch(setFileType("image"));
    dispatch(setStoredDraggedVariables([]));
    location.pathname === "/" && navigate("/send-invitation");
  };

  const handlerUploadingPdf = async(pdfStream, mimeType) => {
    await rapidDB.files.put({
      id: "pdfFile",
      pdfStream,
    });
    dispatch(setPdfDraggedVariables({}));
    dispatch(setMimeType(mimeType));
    // dispatch(setPdfData(pdfStream));
    // dispatch(setFileStream(null));
    dispatch(setFileType("pdf"));
    await getFileStreamFromIndexDB("pdfFile");
    location.pathname === "/" && navigate("/send-invitation");
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    try {
      const fileName = acceptedFiles[0].name;
      const mimeType = acceptedFiles[0].type;
      if (acceptedFiles[0].type.includes("image")) {
        const imageStream = await fileToDataURL(acceptedFiles[0]);
        handleUploadingImage(imageStream, mimeType);
      } else if (fileName.endsWith(".xlsx") || fileName.endsWith(".xls")) {
        const excel = await convertExcelToJson(acceptedFiles[0]);

        dispatch(setStoredDraggedVariables([]));
        dispatch(setPdfDraggedVariables({}));
        dispatch(setExcelData(excel));
      } else if (fileName.endsWith(".pdf")) {
        const pdfStream = await fileToDataURL(acceptedFiles[0]);
        handlerUploadingPdf(pdfStream, mimeType);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: _inputProps,
  });

  const renderPDF = useMemo(() => {
    return (
      fileType === "pdf" &&
      numPages !== 0 &&
      pdfBuffer && (
        <div className="render-pdf-wrapper">
          {Array.from(new Array(numPages), (el, index) => (
            <div className="pdf-page" key={`pdf-page.${index + 1}`}>
              <PreviewPdf pdfBuffer={pdfBuffer} pageNo={index + 1} />
            </div>
          ))}
        </div>
      )
    );
  }, [pdfBuffer, numPages]);

  return (
    <div className="h-100 w-100">
      <div
        className="d-flex  align-items-center justify-content-center custom-input-wrapper "
        id="upload-file-dropzone"
      >
        <input {...getInputProps()} hidden />

        {!fileStream && !pdfData && (
          <Button
            variant="light"
            className={`d-flex align-items-center justify-content-around select-file-button`}
            {...getRootProps()}
          >
            {file_icon()}
            <span className={`fs-22 fw-400  text-primary`}>Select File</span>
          </Button>
        )}
        {(fileStream || pdfData) &&
          // !excelData &&
          isUploadExcel &&
          isAddingManually && (
            <div className="d-flex flex-column  h-100 w-100 p-15">
              <div className="d-flex justify-content-end">
                <Button
                  variant="light"
                  {...getRootProps()}
                  id="upload-file-dropzone"
                  className={`d-flex align-items-center justify-content-center upload-data-button`}
                >
                  {/* <img src={excel_icon} alt="" /> */}
                  {excel_icon()}
                  <span className={`ps-2 fs-16 fw-500 text-success`}>
                    Upload data
                  </span>
                </Button>
                <Button
                  variant="light"
                  className={`d-flex align-items-center ms-2 justify-content-center upload-data-button`}
                >
                  {/* <img src={excel_icon} alt="" /> */}
                  {export_icon(40,40)}
                  <span className={`ps-2 fs-16  fw-500 `}>
                    Export excel
                  </span>
                </Button>
              </div>
              <hr />
              <div className="my-2 overflow-auto custom-scrollbar">
                <EditableTable />
              </div>
            </div>
          )}

        {fileStream && !isUploadExcel && fileType === "image" && (
          <div className="file-preview-wrapper d-flex">
            {imgUrl && !isUploadExcel && (
              <img
                src={imgUrl}
                className="image-preview w-100 h-auto "
                alt=""
              />
            )}
          </div>
        )}
        {pdfData && !isUploadExcel && fileType === "pdf" && (
          <div className="file-preview-wrapper custom-scrollbar d-flex">
            {renderPDF}
          </div>
        )}

        {!isAddingManually && isUploadExcel && excelData && (
          <div className="table-wrapper h-100 d-flex flex-column justify-content-center">
            <div className="guest-data-wrapper custom-scrollbar">
              <GuestData />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadFile;
