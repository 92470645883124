import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTextMessage } from "../../../redux-slice/messageSlice";
import "./index.scss";
import { Button } from "react-bootstrap";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";

const MessageWriter = () => {
  const { textMessage } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    dispatch(setTextMessage(e.target.value));
  };

  const handleStyleText = (type) => {
    const textarea = document.getElementById("message-writer");
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    const selectedText = textarea.value.substring(startPos, endPos).trim();
    const newText =
      type === "bold"
        ? `*${selectedText}*`
        : type === "italic"
        ? `_${selectedText}_`
        : `~${selectedText}~`;

    if (
      (!String(selectedText).startsWith("*") ||
        !String(selectedText).endsWith("*")) &&
      (!String(selectedText).startsWith("_") ||
        !String(selectedText).endsWith("_")) &&
      (!String(selectedText).startsWith("~") ||
        !String(selectedText).endsWith("~"))
    ) {
      addTextStyle(newText, textarea, startPos, endPos);
    } else if (type === "bold") {
      if (selectedText.includes("*")) {
        removeTextStyle(selectedText, textarea, startPos, endPos, "*");
      } else {
        addTextStyle(newText, textarea, startPos, endPos);
      }
    } else if (type === "italic") {
      if (selectedText.includes("_")) {
        removeTextStyle(selectedText, textarea, startPos, endPos, "_");
      } else {
        addTextStyle(newText, textarea, startPos, endPos);
      }
    } else if (type === "strike") {
      if (selectedText.includes("~")) {
        removeTextStyle(selectedText, textarea, startPos, endPos, "~");
      } else {
        addTextStyle(newText, textarea, startPos, endPos);
      }
    }
  };

  const removeTextStyle = (selectedText, textarea, startPos, endPos, style) => {
    const newText = selectedText.replaceAll(style, "");
    textarea.value =
      textarea.value.substring(0, startPos) +
      newText +
      textarea.value.substring(endPos);
    dispatch(setTextMessage(textarea.value));
  };

  const addTextStyle = (newText, textarea, startPos, endPos) => {
    textarea.value =
      textarea.value.substring(0, startPos) +
      newText +
      textarea.value.substring(endPos);
    dispatch(setTextMessage(textarea.value));
  };

  return (
    <div className="message-writer-wrapper">
      <div>
        <div className="message-writer-toolbar">
          <Button variant="light" onClick={() => handleStyleText("bold")}>
            <b>B</b>
          </Button>
          <Button variant="light" onClick={() => handleStyleText("italic")}>
            <i>I</i>
          </Button>
          <Button variant="light" onClick={() => handleStyleText("strike")}>
            <strike>S</strike>
          </Button>
        </div>
        <textarea
          id="message-writer"
          value={textMessage}
          onChange={handleChange}
          className="w-100"
        />
      </div>
    </div>
  );
};

export default MessageWriter;
