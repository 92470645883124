import React from "react";
import { Button, Container, Form } from "react-bootstrap";
import "./index.scss";
import { googleIcon, right_arrow } from "../../assets/icons";
import microsoft_icon from "../../assets/logo/microsoft-logo.png";
import whatsapp_icon from "../../assets/logo/whatsapp-60.png";

const SignUpForm = () => {
  return (
    <div className="d-flex flex-column flex-grow-1  py-10">
      <Container className=" h-100 d-flex align-items-center justify-content-center">
        <div className="sign-up-form-wrapper h-100 d-flex flex-column align-items-center px-10">
          <p className="text-center fs-50 fw-800 py-59 mb-0">
            Sign up for free today
          </p>
          <div className="sign-up-form d-flex justify-content-center">
            <div className="mt-30 w-345">
              <Form.Control
                type="text"
                className="custom-input fs-25"
                placeholder="Full Name"
              />
              <Form.Control
                type="email"
                className="custom-input mt-20 fs-25"
                placeholder="Email"
              />
              <Form.Control
                type="password"
                className="custom-input mt-20 fs-25"
                placeholder="Password"
              />
              <Button
                variant="primary"
                className="sign-up-button mt-20 fs-25 fw-400 d-flex justify-content-evenly align-items-center"
              >
                Create new account {right_arrow("36px", "36px")}
              </Button>
              <div className="mt-40 d-flex align-items-center justify-content-between">
                <p className="mb-0">Sign up with</p>
                <div className="d-flex gap-2 align-items-center">
                  {googleIcon()} <img src={microsoft_icon} alt="" />
                  <img src={whatsapp_icon} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SignUpForm;
