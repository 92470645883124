import React from "react";
import { success_icon } from "../../assets/icons";
import "./index.scss";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setIsShowSuccessMessage } from "../../redux-slice/statusSlice";

const SuccessMessage = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <div className="success-message-wrapper d-flex flex-column align-items-center">
      <div>{success_icon(50, 50)}</div>
      <p className="mt-2 mb-0 fs-25">Successfully Sended all invitations!</p>
      <p className="text-muted">InvitationId : {id}</p>
      <Button
        variant="primary"
        className=""
        size="sm"
        onClick={() => {
          dispatch(setIsShowSuccessMessage(false));
        }}
      >
        Status
      </Button>
    </div>
  );
};

export default SuccessMessage;
