import React from "react";
import NavbarComponent from "../components/navbar";

const PrivacyPage = () => {
  return (
    <div className="bg-yellow">
      <NavbarComponent />
      <div class="container mt-5">
        <h1 class="text-center">Privacy Policy</h1>
        <p class="mt-4">Last updated: 18 August '23.</p>

        <h2>Information We Collect</h2>
        <p>We may collect the following types of information:</p>
        <ul>
          <li>
            Personal Information: We may collect your name, email address, and
            phone number.
          </li>
          <li>
            Usage Information: We may collect information about how you use our
            web application.
          </li>
        </ul>

        <h2>How We Use Your Information</h2>
        <p>We may use the information collected for:</p>
        <ul>
          <li>Sending promotional messages on your behalf via WhatsApp.</li>
          <li>Improving and customizing our services.</li>
        </ul>

        <h2>Sharing Your Information</h2>
        <p>We do not share your personal information with third parties.</p>

        <h2>Your Choices</h2>
        <p>You can:</p>
        <ul>
          <li>Opt out of promotional messages at any time.</li>
          <li>
            Request access, correction, or deletion of your personal
            information.
          </li>
        </ul>

        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at grapes1056@gmail.com.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPage;
