import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const CaptchaModel = (props) => {
  const { secretKey, setSecretKey, downloadZip, onHide } = props;
  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">KeyEntry</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Secret key</Form.Label>
            <Form.Control
              type="password"
              placeholder="secret key"
              autoFocus
              onChange={(e) => setSecretKey(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
        <Button
          disabled={secretKey !== SECRET_KEY}
          onClick={() => {
            downloadZip();
            onHide();
          }}
        >
          Download
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CaptchaModel;
