import React from "react";
import "./step4.scss";
import whatsapp_logo from "../../../assets/logo/whatsapp-logo.png";
import zip_logo from "../../../assets/logo/zip-logo.png";
import invitation from "../../../assets/images/invitation-preview.png";

const Step4 = (props) => {
  const { handleStepNo, selectedStep } = props;

  const stepNumbers = [1, 2, 3, 4];

  return (
    <div className="step4-wrapper d-flex">
      <div className="left-section d-flex">
        <div className="page-no-section">
          {stepNumbers.map((step, i) => {
            return (
              <div
                key={`step-${i + 1}`}
                className={`round-with-number d-flex align-items-center justify-content-center ${
                  i !== 0 ? "mt-30" : ""
                }  fs-35 ${selectedStep === step ? "selected" : ""}`}
                onClick={() => {
                  handleStepNo(step);
                }}
              >
                {step}
              </div>
            );
          })}
        </div>
        <div className="ml-69">
          <div className="">
            <button className="zip-button fs-25 fw-700 text-white px-0">
              <span> Download</span>{" "}
              <img src={zip_logo} className="pl-20 " alt="" />
            </button>
            <br />
            <button className="whatsapp-button fs-25 fw-700 text-white mt-22 px-0">
              <span> Invite using</span>{" "}
              <img src={whatsapp_logo} className="pl-8" alt="" />
            </button>
          </div>
          <div className="secondary-title fs-38 fw-800">
            Preview your Invitation and{" "}
            <span className="text-primary">SEND</span> invitations.
          </div>
        </div>
      </div>
      <div className="right-section">
        <img src={invitation} alt="" />
      </div>
    </div>
  );
};

export default Step4;
