import React from "react";
import "./step2.scss";
import { file_icon } from "../../../assets/icons";
import { Button } from "react-bootstrap";
import excel_logo from "../../../assets/logo/excel-logo.png";
import excel_sample from "../../../assets/images/excel_image.png";

const Step2 = (props) => {
  const { handleStepNo, selectedStep } = props;

  const stepNumbers = [1, 2, 3, 4];

  return (
    <div className="step2-wrapper d-flex">
      <div className=" page-number-section">
        {stepNumbers.map((step, i) => {
          return (
            <div
              key={`step-${i + 1}`}
              className={`round-with-number d-flex align-items-center justify-content-center ${
                selectedStep === step ? "selected" : ""
              } ${i !== 0 ? "mt-30" : ""}  fs-35`}
              onClick={() => {
                handleStepNo(step);
              }}
            >
              {step}
            </div>
          );
        })}
      </div>
      <div className="content-section ">
        <div className="d-flex">
          <div>
            <Button
              variant="light"
              className="d-flex align-items-center justify-content-center select-file-button p-0"
            >
              {file_icon()}
              <span className="ps-4 fs-22 text-primary">Select File</span>
            </Button>
            <div className="d-flex justify-content-end ">
              <div className="excel-icon-wrapper">
                <img src={excel_logo} alt="excel_logo" />
                <p className="mb-0 text-center fs-22">Excel</p>
              </div>
            </div>
          </div>
          <div className="secondary-title fs-38 fw-800">
            <p className="text-primary mb-0">
              Upload <br /> Excel
            </p>
            or CSV file along with Data
          </div>
        </div>
        <div className="excel-sample-wrapper">
          <img src={excel_sample} alt="excel_sample" />
        </div>
      </div>
    </div>
  );
};

export default Step2;
