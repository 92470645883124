import { read, utils, writeFile } from "xlsx";
import { capitalizeFirstLetter, validateString } from "./string";

export const convertExcelToJson = async (excelStream) => {
  const filePromise = new Promise((res, rej) => {
    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            defval: "",
          });
          const excelHeaders = Object.keys(rows[0]);
          const guestData = formatRows(rows);
          res({ guestData, excelHeaders });
        }
      };
      reader.readAsArrayBuffer(excelStream);
    } catch (error) {
      rej(error);
    }
  });

  return await filePromise;
};

const formatRows = (rows) => {
  const removedEmptyRows = removeEmptyRows(rows);
  const formattedRows = removedEmptyRows.map((row) => {
    const formObj = {};
    Object.entries(row).map((values) => {
      const key = values[0].toString().trim();
      const value = capitalizeFirstLetter(values[1].toString());
      formObj[key] = value;
      return "";
    });
    return formObj;
  });
  return formattedRows;
};

export const removeEmptyRows = (rows) => {
  const formattedRows = rows.filter((row) => {
    const ans = Object.entries(row).map(
      (values) => !!values[1].toString().trim()
    );
    return ans.some((val) => val);
  });
  return formattedRows.length > 0 ? formattedRows : [];
};

export const validateExcel = (rows) => {
  const invalidRows = [];
  if (rows?.length > 0) {
    rows?.map((row, i) => {
      const isValid = Object.values(row)?.every((value) =>
        validateString(String(value))
      );
      if (!isValid) {
        invalidRows.push(i + 1);
      }
      return;
    });
    return invalidRows.length > 0 ? invalidRows.join(", ") : "";
  } else {
    return "";
  }
};

export const exportExcelFile = (headers, values) => {
  let filename = "Invitation.xlsx";
  const heading = [headers];

  var ws = utils.json_to_sheet(values, {
    origin: "A2",
    skipHeader: true,
  });

  var wscols = [
    { wch: 5 },
    { wch: 25 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 15 },
    { wch: 10 },
    { wch: 15 },
  ];

  ws["!cols"] = wscols;
  utils.sheet_add_aoa(ws, heading, { origin: "A1" });
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "invitation");
  writeFile(wb, filename, {
    type: "buffer",
    cellStyles: true,
  });
};
