export const getFromLocalStorage = (key) => {
  return localStorage.getItem(key) || "";
};

export const getNgrokUrlFromLocalStorage = () => {
  return (
    localStorage.getItem(process.env.REACT_APP_NGROK_URL_KEY) ||
    process.env.REACT_APP_MAIN_BACKEND_BASEURL
  );
};

export const setInLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
  return value;
};
