import React, { useEffect, useState } from "react";
import "./index.scss";
import { Form } from "react-bootstrap";
import { addFontFamily } from "../../../utils";
import { useSelector } from "react-redux";
// import { SketchPicker } from "react-color";
import ColorPicker from "../../colorPicker";

const VariableCustomization = (props) => {
  const {
    variable,
    updateFontColor,
    handleFontFamily,
    updateFontSize,
    innerIndex,
    index,
  } = props;

  const { type, fontType, fontSize, top } = variable;

  const { fileType } = useSelector((state) => state.files);

  const [activeFontFamily, setActiveFontFamily] = useState("Poppins");
  const [sortedAllFontTypes, setSortedAllFontTypes] = useState([]);

  const { r, g, b } = variable.color;

  const fontFamilies = [
    "https://fonts.googleapis.com/css2?family=Noto+Sans+Gujarati:wght@300;400;500;600;700;800;900&display=swap",
    "https://fonts.googleapis.com/css2?family=Rasa:wght@400;500;600;700&display=swap",
  ];

  const fontTypes = [
    { property: "poppins", style: "poppins", name: "Poppins", type: "english" },
    { property: "Rasa", style: "rasaGujarati", name: "રાસા", type: "gujrati" },
    {
      property: "aakar",
      style: "aakarGujarati",
      name: "આકાર",
      type: "gujrati",
    },
    {
      property: "padmaa",
      style: "padmaGujarati",
      name: "પદ્મા",
      type: "gujrati",
    },
    { property: "Roboto", style: "roboto", name: "Roboto", type: "english" },
    {
      property: "Montserrat",
      style: "montserrat",
      name: "Montserrat",
      type: "english",
    },
    { property: "Akshar", style: "akshar", name: "Akshar", type: "english" },
    {
      property: "Lohit Gujrati",
      style: "lohitGujarati",
      name: "લોહિત ગુજરાતી",
      type: "gujrati",
    },
    {
      property: "Noto Sans Gujarati",
      style: "notoSansGujarati",
      name: "નોટો સંસ ગુજરાતી",
      type: "gujrati",
    },
    // {
    //   property: "Tiro Devanagari Hindi",
    //   style: "tiroHindi",
    //   name: "Tiro Devanagari Hindi",
    //   type: "hindi",
    // },
  ];

  const sortAllFontTypes = () => {
    const sortedAllFontTypes = fontTypes.sort((a, b) =>
      a.type < b.type ? -1 : a.type > b.type ? 1 : 0
    );
    return sortedAllFontTypes;
  };

  const fontSizes = 24;

  useEffect(() => {
    // addFontFamily(fontFamilies);
    // eslint-disable-next-line
  }, [activeFontFamily]);

  useEffect(() => {
    setSortedAllFontTypes(sortAllFontTypes());
    // eslint-disable-next-line
  }, []);
  const colorPicker = (color) => {
    if (fileType === "image") {
      updateFontColor(color, innerIndex);
      return;
    }
    if (fileType === "pdf") {
      updateFontColor(color, innerIndex, index);
      return;
    }
  };

  const fontSizeHandler = (size) => {
    const updatedTop = countNewTop(fontSize, size, top);
    if (fileType === "image") {
      updateFontSize(Number(size), innerIndex, "", updatedTop);
      return;
    }
    if (fileType === "pdf") {
      updateFontSize(Number(size), innerIndex, index, updatedTop);
      return;
    }
  };

  const countNewTop = (oldSize, newSize, top) => {
    const difference = Number(oldSize) - Number(newSize);
    if (difference > 0) {
      const updatedTop = Number(top) + Number(difference) * 0.75;
      return updatedTop;
    }
    if (difference < 0) {
      const updatedTop = Number(top) + Number(difference) * 0.75;
      return updatedTop;
    }
  };

  const increaseFontSize = () => {
    let size = Number(fontSize) + 1;
    let updatedTop = Number(top) - 0.75;
    if (fileType === "image") {
      updateFontSize(size, innerIndex, "", updatedTop);
      return;
    }
    if (fileType === "pdf") {
      updateFontSize(size, innerIndex, index, updatedTop);
      return;
    }
  };
  const decreaseFontSize = () => {
    let size = Number(fontSize) - 1;
    let updatedTop = Number(top) + 0.75;
    if (fileType === "image") {
      updateFontSize(size, innerIndex, "", updatedTop);
      return;
    }
    if (fileType === "pdf") {
      updateFontSize(size, innerIndex, index, updatedTop);
      return;
    }
  };
  const fontTypes_ = [];
  return (
    <div className={`variable-customization ${index === 0 ? "mt-10" : ""} `}>
      <div className={`variable-box d-flex align-items-center`}>
        <div className="dots-wrapper d-flex">
          <div className="left-dots">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
          <div className="right-dots">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </div>
        {fileType === "pdf" ? (
          <div className="d-flex justify-content-between align-items-center flex-grow-1">
            <p className="mb-0 fs-22 text-capitalize">{type}</p>
            <div className="page-no d-flex justify-content-center align-items-center fs-16">
              {index + 1}
            </div>
          </div>
        ) : (
          <p className="mb-0 fs-22 text-capitalize">{type}</p>
        )}
      </div>
      <div className="font-family-handler d-flex justify-content-evenly align-items-center">
        <p
          className="text-decoration-underline fs-25 fw-600 mb-0"
          style={{ fontFamily: fontType }}
        >
          Aa
        </p>
        <div>
          <Form.Select
            aria-label="Default select example"
            className="custom-form-select"
            onChange={(e) => {
              setActiveFontFamily(JSON.parse(e.target.value)?.name);
              if (fileType === "image") {
                handleFontFamily(JSON.parse(e.target.value), innerIndex);
                return;
              }
              if (fileType === "pdf") {
                handleFontFamily(JSON.parse(e.target.value), innerIndex, index);
                return;
              }
            }}
            value={JSON.stringify(variable.fontType)}
          >
            {sortedAllFontTypes?.length > 0 &&
              sortedAllFontTypes.map((font, i) => {
                return (
                  <React.Fragment key={`font.${i}`}>
                    {sortedAllFontTypes[i].type === font.type &&
                      !fontTypes_.includes(font.type) && (
                        <>
                          <option
                            className="select-divider"
                            disabled
                          >{`${font.type} Fonts`}</option>
                          {!fontTypes_.includes(font.type) &&
                            fontTypes_.push(font.type)}
                        </>
                      )}
                    <option value={JSON.stringify(font)}>{font.name}</option>
                  </React.Fragment>
                );
              })}
          </Form.Select>
        </div>
      </div>
      <div className="color-picker-handler ">
        <div className="d-flex align-items-center h-100 ">
          <div
            className=""
            // style={{ backgroundColor: `rgba(${r},${g},${b})` }}
          >
            <ColorPicker
              onColorChange={colorPicker}
              color={variable.color.string}
            />
          </div>
          <span className="">{`rgb(${r},${g},${b})`}</span>
        </div>
      </div>
      <div className="font-size-handler d-flex justify-content-evenly align-items-center row mx-0">
        <p
          className="mb-0 fs-22 col-4 text-center cursor-pointer"
          onClick={decreaseFontSize}
        >
          -
        </p>
        <div className="col-4 text-center">
          <Form.Control
            type="number"
            className="text-center"
            id="myInput"
            list="mySelect"
            size="sm"
            value={fontSize}
            onChange={(e) => {
              fontSizeHandler(e.target.value);
            }}
          />
          <datalist id="mySelect">
            {Array.from(new Array(fontSizes), (el, i) => {
              return (
                i % 2 === 1 && (
                  <option value={i + 1} key={`font.${i}`}>
                    {i + 1}
                  </option>
                )
              );
            })}
          </datalist>
        </div>
        <p
          className="mb-0 fs-22 col-4 text-center cursor-pointer"
          onClick={increaseFontSize}
        >
          +
        </p>
      </div>
    </div>
  );
};

export default VariableCustomization;
