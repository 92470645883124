import { uploadAllDataToDb } from "../api/api";

export const createInvitation = async (
  link,
  guests,
  coordinates,
  mimeType,
  message,
  contact
) => {
  try {
    const payload = {
      link,
      guests,
      coordinates,
      mimeType,
      message,
      contact,
    };
    const res = await uploadAllDataToDb(payload);
    const invitation_id = res?.data?.invitation_id;
    return invitation_id;
  } catch (error) {
    console.log("createInvitation-error", error);
  }
};
